<template>
  <div class="courses-details">

    <breadcrumb
      :title="'Подробности новости'"
      :subtitle="blogDetails.title ? blogDetails.title : ''"
    />

    <div class="mentor-details py-4">
      <b-container>
        <b-row>
          <b-col md="12" v-if="blogDetails.image" class="justify-content-center text-center mb-3">
            <b-img :src="`https://edu.startupchoikhona.tj/backend${blogDetails.image}`" class="w-100" style="max-width: 700px"></b-img>
          </b-col>
          <b-col md="12" class="text-justify" v-html="blogDetails.description">

          </b-col>
          <b-col md="12">
            <h5>Автор: {{blogDetails.author.name}}</h5>
          </b-col>
        </b-row>
      </b-container>

    </div>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Breadcrumb from '@/views/landing/components/Breadcrumb.vue'
import {
  BImg,
  BLink,
  BContainer,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BFormRating,
  BTab,
  BTabs,
  BCardText,
  BPagination,
} from 'bootstrap-vue'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BImg,
    BLink,
    AppCollapse,
    AppCollapseItem,
    Breadcrumb,
    BContainer,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BFormRating,
    BTab,
    BTabs,
    BCardText,
    BPagination,
  },
  data() {
    return {
      blogDetails: {},
    }
  },
  setup() {
    const { id } = router.currentRoute.params
    return {
      id,
    }
  },
  created() {
    this.view()
  },
  methods: {
    view() {
      this.$http.get(`https://edu.startupchoikhona.tj/backend/api/website/blogs/${this.id}/view`, {})
        .then(response => {
          const { data } = response.data
          this.blogDetails = data
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
    },
  },
}
</script>

<style scoped>

</style>
